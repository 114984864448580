<template>
  <IssueDetailsOrganismStyled v-if="issueId">
    <ApolloQuery :query="ASSET_ISSUE_META_QUERY" :variables="variables" :skip="!issueId" :fetchPolicy="'network-only'">
      <template v-slot="{ result: { error, data }, isLoading }">
        <TalpaLoaderWrapper v-if="!!isLoading" />
        <ErrorAtom v-else-if="error" :error="error" />
        <template v-else-if="data && data.assetIssue">
          <IssueMetaMolecule :issue="data.assetIssue" />
        </template>
        <NoDataAtom v-else />
      </template>
    </ApolloQuery>
    <ApolloQuery :query="ASSET_ISSUE_RAW_DATA_QUERY" :variables="variables" :skip="!issueId">
      <template v-slot="{ result: { error, data }, isLoading }">
        <ErrorAtom v-if="error" :error="error" />
        <IssueRawDataOrganism v-else :isLoading="!!isLoading" :assetIssueRawData="data?.assetIssue" />
      </template>
    </ApolloQuery>
  </IssueDetailsOrganismStyled>
  <WarningStyled v-else>
    <p>{{ $t('maintenance.selectedIssueNotInTimeframe') }}</p>
  </WarningStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaLoaderWrapper } from '@common/components'

import ASSET_ISSUE_META_QUERY from '#/graphql/operations/maintenance/assetIssueMetaQuery.gql'
import ASSET_ISSUE_RAW_DATA_QUERY from '#/graphql/operations/maintenance/assetIssueRawDataQuery.gql'
import IssueRawDataOrganism from './IssueRawDataOrganism'
import IssueMetaMolecule from './IssueMetaMolecule'
import ErrorAtom from '@/components/Atomic/Atoms/ErrorAtom'
import NoDataAtom from '@/components/Atomic/Atoms/NoDataAtom'

const IssueDetailsOrganismStyled = styled('div')`
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 0.5rem;
  height: calc(100% - 1rem);
`

const WarningStyled = styled('div')`
  display: flex;
  justify-content: center;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 0.5rem;
`

export default {
  inject: ['uiSettings'],
  components: {
    IssueDetailsOrganismStyled,
    IssueMetaMolecule,
    IssueRawDataOrganism,
    WarningStyled,
    TalpaLoaderWrapper,
    ErrorAtom,
    NoDataAtom,
  },
  props: {
    issueId: {
      type: String,
    },
  },
  data() {
    return {
      isExpanded: true,
      issue: null,
      ASSET_ISSUE_META_QUERY,
      ASSET_ISSUE_RAW_DATA_QUERY,
    }
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2).toLowerCase() + replacedDates.slice(2, 5)
    },
    variables() {
      return {
        where: {
          id: this.issueId,
          locale: this.locale,
        },
      }
    },
  },
}
</script>
