<template>
  <IssueRawDataMoleculeStyled>
    <header>
      <TitleWithBorderAtom :title="$t('maintenance.rawDataForInsight')" />
      <GoToAtom :to="routeName" :label="label" v-if="!isLoading && hasSignalReadPermission" />
    </header>
    <IssueRawDataActivitiesAtom :isLoading="isLoading" :activities="assetIssueRawData?.activities || []" />
    <BlockChartInteractionMolecule v-if="!isLoading">
      <IssueRawDataChartAtom
        :isLoading="isLoading"
        :assetIssue="assetIssueRawData"
        :activities="assetIssueRawData?.activities || []"
        :relatedSignals="assetIssueRawData?.relatedSignals || []"
      />
    </BlockChartInteractionMolecule>
  </IssueRawDataMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import BlockChartInteractionMolecule from '@/components/Atomic/Molecules/BlockChartInteractionMolecule'
import TitleWithBorderAtom from '@/components/Atomic/Atoms/TitleWithBorderAtom'
import IssueRawDataActivitiesAtom from './IssueRawDataActivitiesAtom'
import IssueRawDataChartAtom from './IssueRawDataChartAtom'
import GoToAtom from '@/components/Atomic/Atoms/GoToAtom.vue'
import permissionsMixin from '@/mixins/permissions'

const IssueRawDataMoleculeStyled = styled('div')`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 600px;
  > header {
    gap: 1rem;
    > a {
      float: right;
      margin-top: 1rem;
    }
  }
`
export default {
  inject: ['theme'],
  mixins: [permissionsMixin],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    assetIssueRawData: {
      type: Object,
    },
  },
  components: {
    BlockChartInteractionMolecule,
    IssueRawDataMoleculeStyled,
    TitleWithBorderAtom,
    IssueRawDataActivitiesAtom,
    IssueRawDataChartAtom,
    GoToAtom,
  },
  data() {
    return {
      signals: [],
    }
  },
  computed: {
    assetId() {
      return this.$route?.params?.id
    },
    signalIds() {
      return this.assetIssueRawData?.relatedSignals.map(({ signal }) => signal?.id)
    },
    routeName() {
      const signals = this.signalIds?.toString()
      const startTS = this.assetIssueRawData?.start ? Date.parse(this.assetIssueRawData?.start) : null
      return {
        name: 'signals',
        query: {
          assetId: this.assetId,
          ...(signals && {
            signalIds: signals,
            ...(startTS && { start: startTS }),
          }),
        },
      }
    },
    label() {
      return this.$tc('actions.goToSignals')
    },
    hasSignalReadPermission() {
      return this.permissions?.read?.find(p => p.name === 'signal_read')
    },
  },
}
</script>
