<template>
  <IssueMetaMoleculeStyled>
    <HeaderWithIconAtom>
      <template v-slot:icon><TalpaIcon class="icon" :scope="'Misc'" :name="'BulbIcon'" /></template>
      <template v-slot:title>{{ issue.label ? issue.label : $t('messages.noDataAvailable') }}</template>
    </HeaderWithIconAtom>
    <SubHeaderStyled>
      <KeyValueV2Atom :label="'SPN'" :value="issue.spn" />
      <KeyValueV2Atom :label="'PGN'" :value="issue.pgn ?? '-'" />
      <KeyValueV2Atom :label="'FMI'" :value="issue.fmi" />
      <KeyValueV2Atom :label="$t('maintenance.fmiDescription')" :value="issue.fmiDescription ?? '-'" />
    </SubHeaderStyled>
    <TextSectionAreaStyled>
      <TextSectionMolecule
        class="summary"
        :title="$t('maintenance.summary')"
        :isMarkdown="true"
        :text="issue.summary ?? $t('messages.noDataAvailable')"
      >
      </TextSectionMolecule>
      <TextSectionMolecule
        class="details"
        :title="$t('maintenance.details')"
        :isMarkdown="true"
        :text="issue.description ?? $t('messages.noDataAvailable')"
      >
      </TextSectionMolecule>
      <TextSectionMolecule
        class="action"
        :isMarkdown="true"
        :title="$t('maintenance.recommendedAction')"
        :text="issue.action ?? $t('messages.noDataAvailable')"
      />
      <TextSectionMolecule
        class="spare"
        :isMarkdown="true"
        :title="$tc('maintenance.spareParts', 2)"
        :text="issue.spareParts ?? $t('messages.noDataAvailable')"
      />
    </TextSectionAreaStyled>
  </IssueMetaMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import HeaderWithIconAtom from '@/components/Atomic/Atoms/HeaderWithIconAtom'
import TextSectionMolecule from '@/components/Atomic/Molecules/TextSectionMolecule'

import KeyValueV2Atom from '@/components/Atomic/Atoms/KeyValueV2Atom'
import { TalpaIcon } from '@common/components'

const IssueMetaMoleculeStyled = styled('div')``

const SubHeaderStyled = styled('div')`
  display: flex;
  gap: 3rem;
  padding: 0.5rem 1rem;
  margin-left: 58px;
  flex-direction: row;
  @media (max-width: 425px) {
    grid-gap: 0.5rem;
    margin-left: 0px;
  }
`
const TextSectionAreaStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content min-content;
  grid-gap: 2rem;
  padding: 1rem;
  grid-template-areas:
    'summary'
    'details'
    'action'
    'spare';
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr min-content;
    grid-template-areas:
      'summary details'
      'action spare';
  }
  .summary {
    grid-area: summary;
  }
  .details {
    grid-area: details;
  }
  .action {
    grid-area: action;
  }
  .spare {
    grid-area: spare;
  }
`

export default {
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
  components: {
    IssueMetaMoleculeStyled,
    SubHeaderStyled,
    HeaderWithIconAtom,
    TalpaIcon,
    KeyValueV2Atom,
    TextSectionMolecule,
    TextSectionAreaStyled,
  },
}
</script>
