<template>
  <IssueRawDataChartAtomStyled>
    <VChart ref="chart" :theme="selectedTheme" :option="option" :updateOptions="{ notMerge: true }" autoresize />
  </IssueRawDataChartAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { CustomChart, LineChart } from 'echarts/charts'
import { clipRectByRect } from 'echarts/lib/util/graphic.js'
// import { defaultSeriesFormatTooltip } from 'echarts/lib/component/tooltip/seriesFormatTooltip'
import VChart from 'vue-echarts'
import { ActivityColors } from '@styles/themes'

import { TooltipComponent, DataZoomComponent, GridComponent, TitleComponent, LegendComponent, MarkAreaComponent } from 'echarts/components'
import { DateTime, Interval, Duration } from 'luxon'
import round from '@/utils/round'
import { getAssetIssueHighestSeverityColor } from '@/utils/maintenance/healthUtils'
import { DarkEChartTheme, LightEChartTheme } from '../../../../../../styles/themes/src/chart-themes'

use([
  CanvasRenderer,
  CustomChart,
  LineChart,
  TooltipComponent,
  DataZoomComponent,
  LegendComponent,
  GridComponent,
  TitleComponent,
  MarkAreaComponent,
])

const IssueRawDataChartAtomStyled = styled('div')`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  .tooltip {
    .value {
      font-weight: 600;
      text-align: right;
    }
    .capitalize {
      text-transform: capitalize;
    }
  }
`

function renderItem(params, api) {
  const categoryIndex = api.value(0)
  const start = api.coord([api.value(1), categoryIndex])
  const end = api.coord([api.value(2), categoryIndex])
  const rect1 = {
    x: start[0],
    y: 10,
    width: end[0] - start[0],
    height: 30,
  }
  const rect2 = {
    x: params.coordSys.x,
    y: 10,
    width: params.coordSys.width,
    height: 30,
  }
  const rectShape = clipRectByRect(rect1, rect2)
  return (
    rectShape && {
      type: 'rect',
      transition: ['shape'],
      shape: rectShape,
      style: api.style(),
    }
  )
}

export default {
  inject: ['theme', 'uiSettings'],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    assetIssue: {
      type: Object,
    },
    activities: {
      type: Array,
      required: true,
    },
    relatedSignals: {
      type: Array,
      required: true,
    },
  },
  components: {
    IssueRawDataChartAtomStyled,
    VChart,
  },
  watch: {
    isLoading: {
      handler() {
        this.handleLoadingState()
      },
      immediate: true,
    },
  },
  mounted() {
    this.handleLoadingState()
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').toLowerCase().replace('_', '-')
    },
    selectedTheme() {
      return this.theme.isDark ? DarkEChartTheme : LightEChartTheme
    },
    colors() {
      return this.theme.colors.atomic.chartColorsV2
    },
    timestamps() {
      const start = this.startTimeMillis
      return Array.from({ length: 3600 }, (_, i) =>
        DateTime.fromMillis(start + i * 1000)
          .setLocale(this.locale)
          .toLocaleString(DateTime.TIME_WITH_SECONDS),
      )
    },
    relatedSignalsGroupedByUnit() {
      return this.relatedSignals.reduce((groups, relatedSignal) => {
        const key = relatedSignal.signal.unit
        if (groups.has(key)) {
          groups.get(key).push(relatedSignal)
        } else {
          groups.set(key, [relatedSignal])
        }
        return groups
      }, new Map())
    },
    issueStart() {
      if (!this.assetIssue?.start) {
        return null
      }
      return DateTime.fromISO(this.assetIssue.start)
    },
    intervalOfHour() {
      if (!this.issueStart) {
        return null
      }
      const iv = Interval.fromDateTimes(this.issueStart.minus({ minutes: 30 }), this.issueStart.plus({ minutes: 30 }))
      return iv
    },
    issueInterval() {
      if (!this.issueStart) {
        return null
      }
      if (!this.assetIssue?.end) {
        return Interval.fromDateTimes(this.issueStart, this.intervalOfHour.end)
      }
      const end = DateTime.fromISO(this.assetIssue.end)
      const maxEnd = end.toMillis() > this.intervalOfHour.end.toMillis() ? this.intervalOfHour.end : end
      const iv = Interval.fromDateTimes(this.issueStart, maxEnd)
      return iv
    },
    issueIntervalAxisIndex() {
      if (!this.startTimeMillis || !this.issueInterval) {
        return {}
      }
      const startMs = this.issueInterval.start.toMillis() - this.startTimeMillis
      const endMs = this.issueInterval.end.toMillis() - this.startTimeMillis
      const start = startMs / 1000
      const end = endMs / 1000
      return {
        start,
        end,
      }
    },
    startTimeMillis() {
      if (!this.intervalOfHour) {
        return null
      }
      return this.intervalOfHour.start.toMillis()
    },
    activityData() {
      const mapped = this.activities.map(activity => {
        const start = DateTime.fromISO(activity.start)
        const end = DateTime.fromISO(activity.end)
        const startMs = start.toMillis()
        const endMs = end.toMillis()
        const duration = activity.duration * 1000
        return {
          name: activity.type,
          interval: Interval.fromDateTimes(start, end),
          value: [0, startMs, startMs + duration, duration],
          valueFoo: [0, (startMs - this.startTimeMillis) / 1000, (endMs - this.startTimeMillis) / 1000, duration / 1000],
          itemStyle: {
            color: ActivityColors[activity.type] || this.theme.colors.primary,
          },
        }
      })
      return mapped
    },
    xAxis() {
      if (!this.startTimeMillis) {
        return []
      }
      const start = this.startTimeMillis
      return [
        {
          min: start,
          max: start + 3600 * 1000,
          id: 'activities',
          position: 'bottom',
          offset: 20,
          scale: false,
          type: 'value',
          axisLabel: {
            formatter: val => {
              const millis = val - this.issueStart
              const isNegative = millis < 0
              const duration = isNegative ? Duration.fromMillis(-millis) : Duration.fromMillis(millis)
              return `${isNegative ? '-' : '+'} ${duration.shiftTo('minutes', 'seconds').normalize().toHuman({
                unitDisplay: 'short',
              })}`
            },
          },
          axisPointer: {
            show: false,
            label: {
              show: false,
            },
          },
          data: Array.from({ length: 3600 }, (_, i) => this.startTimeMillis + i * 1000),
        },
        {
          type: 'category',
          id: 'other',
          position: 'bottom',
          data: this.timestamps,
        },
      ]
    },
    yAxis() {
      if (!this.startTimeMillis) {
        return []
      }
      const activityYAxis = [
        {
          id: 'activities',
          type: 'value',
          position: 'left',
          show: false,
          data: ['Activities'],
        },
        {
          id: 'issue',
          type: 'value',
          position: 'left',
          show: false,
          data: [1],
        },
      ]
      const other = Array.from(this.relatedSignalsGroupedByUnit.keys()).map((key, i) => ({
        id: key,
        name: key,
        type: 'value',
        unit: key,
        position: 'left',
        // min: 0,
        offset: i * 80,
        nameLocation: 'end',
        nameGap: 20,
        nameTextStyle: {
          align: 'right',
        },
      }))
      const yAxis = activityYAxis.concat(other)
      return yAxis
    },
    series() {
      if (!this.startTimeMillis) {
        return []
      }
      const activitySeries = [
        {
          type: 'custom',
          name: 'Activities',
          renderItem: renderItem,
          xAxisId: 'activities',
          yAxisId: 'activities',
          itemStyle: {
            opacity: 0.8,
          },
          encode: {
            x: [1, 2],
            y: 0,
          },
          data: this.activityData,
        },
        {
          name: 'issue',
          yAxisId: 'issue',
          xAxisId: 'other',
          type: 'line',
          connectNulls: false,
          markArea: this.issueIntervalAxisIndex
            ? {
                silent: true,
                itemStyle: {
                  opacity: 0.25,
                  color: getAssetIssueHighestSeverityColor(this.assetIssue, this.theme),
                },
                data: [
                  [
                    {
                      xAxis: this.issueIntervalAxisIndex.start,
                    },
                    {
                      xAxis: this.issueIntervalAxisIndex.end,
                    },
                  ],
                ],
              }
            : undefined,
          data: [],
        },
      ]
      const other = []
      for (const [groupName, relatedSignals] of this.relatedSignalsGroupedByUnit) {
        relatedSignals.forEach(relatedSignal => {
          const data = relatedSignal?.data?.dataPoints
          other.push({
            name: relatedSignal.signal.name,
            unit: relatedSignal.signal.unit,
            yAxisId: groupName,
            xAxisId: 'other',
            type: 'line',
            connectNulls: false,
            data,
          })
        })
      }
      const series = other.concat(activitySeries)
      return series
    },
    option() {
      return {
        tooltip: {
          className: 'tooltip',
          trigger: 'axis',
          formatter: params => {
            const param = params[0]
            const millis = param.dataIndex * 1000 + this.startTimeMillis
            const dt = DateTime.fromMillis(millis)
            const timeRow = `<tr><td colspan="4">${dt.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}<td></tr>`
            const activity = this.getActivityByMillis(millis)
            const activityColor = activity?.itemStyle?.normal?.color
            const activityRow = activity
              ? `<tr>
              <td class="marker">${param.marker.replace(param.color, activityColor)}</td>
              <td class="dimension capitalize">${this.$t('activity')}: ${this.$t(`activityTypes.${activity.name}`)}</td>
              <td class="value">${activity.value[3] / 1000}</td>
              <td class="unit">sec</td>
            </tr>`
              : ''
            const dataRows = params.reduce((html, param) => {
              html += `<tr>
                <td class="marker">${param.marker}</td>
                <td class="dimension">${param.seriesName}</td>
                <td class="value">${this.safeRoundValue(param.value, 2)}</td>
                <td class="unit">${this.series[param.seriesIndex]?.unit}</td>
              </tr>`
              return html
            }, '')
            return `<table>${timeRow}${activityRow}${dataRows}</table>`
          },
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
            label: {
              color: this.theme.isDark ? this.theme.colors.atomic.black : this.theme.colors.white,
            },
          },
        },
        title: {
          text: '',
          left: 'center',
        },
        dataZoom: [
          {
            type: 'slider',
            filterMode: 'weakFilter',
            bottom: 65,
          },
          {
            type: 'inside',
            filterMode: 'weakFilter',
          },
        ],
        grid: {
          containLabel: true,
          top: 50,
          bottom: 105,
          right: 70,
          left: 70,
        },
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
      }
    },
  },
  methods: {
    getActivityByMillis(millis) {
      const dt = DateTime.fromMillis(millis)
      return this.activityData.find(activity => activity.interval.contains(dt)) || null
    },
    safeRoundValue(value) {
      if (value === null || value === undefined) {
        return '-'
      }
      return round(value, 2)
    },
    handleLoadingState() {
      const isLoading = this.isLoading
      const eChart = this.$refs.chart?.chart
      if (eChart) {
        if (isLoading) {
          eChart.showLoading()
        } else {
          eChart.hideLoading()
        }
      }
    },
  },
}
</script>
