var render = function () {
  var _vm$assetIssueRawData, _vm$assetIssueRawData2, _vm$assetIssueRawData3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IssueRawDataMoleculeStyled', [_c('header', [_c('TitleWithBorderAtom', {
    attrs: {
      "title": _vm.$t('maintenance.rawDataForInsight')
    }
  }), !_vm.isLoading && _vm.hasSignalReadPermission ? _c('GoToAtom', {
    attrs: {
      "to": _vm.routeName,
      "label": _vm.label
    }
  }) : _vm._e()], 1), _c('IssueRawDataActivitiesAtom', {
    attrs: {
      "isLoading": _vm.isLoading,
      "activities": ((_vm$assetIssueRawData = _vm.assetIssueRawData) === null || _vm$assetIssueRawData === void 0 ? void 0 : _vm$assetIssueRawData.activities) || []
    }
  }), !_vm.isLoading ? _c('BlockChartInteractionMolecule', [_c('IssueRawDataChartAtom', {
    attrs: {
      "isLoading": _vm.isLoading,
      "assetIssue": _vm.assetIssueRawData,
      "activities": ((_vm$assetIssueRawData2 = _vm.assetIssueRawData) === null || _vm$assetIssueRawData2 === void 0 ? void 0 : _vm$assetIssueRawData2.activities) || [],
      "relatedSignals": ((_vm$assetIssueRawData3 = _vm.assetIssueRawData) === null || _vm$assetIssueRawData3 === void 0 ? void 0 : _vm$assetIssueRawData3.relatedSignals) || []
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }