<template>
  <IssueRawDataActivitiesAtomStyled>
    <LoadingDots v-if="isLoading" />
    <div v-else class="group" v-for="[key, group] of groupedActivities" :key="key">
      <CircleStyle :color="group.color" />
      <span class="type">{{ $t('activityTypes.' + group.id) | capitalize }}:</span>
      <span>{{ percentage(group.totalDuration) }}</span>
      <span class="dot">&middot;</span>
      <span>{{ group.totalDuration | humanDuration }}</span>
    </div>
  </IssueRawDataActivitiesAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { LoadingDots } from '@common/components'
import { ActivityColors } from '@styles/themes'
import convert from '@common/convert-units'

const IssueRawDataActivitiesAtomStyled = styled('div')`
  display: grid;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  grid-gap: 2rem;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-auto-rows: 2rem;
  font-size: 14px;
  > .group {
    display: flex;
    align-items: center;
    .type {
      margin-right: 0.5rem;
    }
    .dot {
      margin: 0 0.25rem;
    }
  }
`

const CircleStyle = styled('span')`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${({ color }) => color};
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    activities: {
      type: Array,
      required: true,
    },
  },
  components: {
    IssueRawDataActivitiesAtomStyled,
    LoadingDots,
    CircleStyle,
  },
  filters: {
    humanDuration(val) {
      const best = convert(val).from('s').toBest()
      return `${Math.round(best.val * 10) / 10} ${best.unit}`
    },
  },
  computed: {
    sumOfAllDurations() {
      return this.activities.reduce((sum, activity) => sum + activity.duration, 0)
    },
    groupedActivities() {
      return this.activities.reduce((group, activity) => {
        if (group.has(activity.type)) {
          const g = group.get(activity.type)
          g.totalDuration += activity.duration
          g.activities.push(activity)
        } else {
          group.set(activity.type, {
            id: activity.type,
            totalDuration: activity.duration,
            activities: [activity],
            color: ActivityColors[activity.type],
          })
        }
        return group
      }, new Map())
    },
  },
  methods: {
    percentage(duration) {
      if (this.sumOfAllDurations === 0) {
        return '-'
      }
      return `${Math.round((duration / this.sumOfAllDurations) * 1000) / 10}%`
    },
  },
}
</script>
