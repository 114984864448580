<template>
  <HeaderWithIconAtomStyled>
    <IconStyled> <slot name="icon" /></IconStyled>
    <TitleStyled> <slot name="title" /> </TitleStyled>
  </HeaderWithIconAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const HeaderWithIconAtomStyled = styled('div')`
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-rows: 2.5rem;
  border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  align-items: center;
  padding: 0.5rem 1rem;
  @media (max-width: 1024px) {
    grid-template-rows: 4rem;
  }
`
const IconStyled = styled('div')`
  align-items: center;
  display: flex;
  > .icon {
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    svg.icon {
      width: max-content;
      height: max-content;
    }
  }
`
const TitleStyled = styled('div')`
  color: ${({ theme }) => theme.colors.atomic.textSecondary};
  padding: 0.5rem;
  margin-left: 10px;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;

  @media (max-width: 425px) {
    font-size: 18px;
  }
`
export default {
  components: {
    HeaderWithIconAtomStyled,
    IconStyled,
    TitleStyled,
  },
}
</script>
