<template>
  <table-widget-styled>
    <table-v2
      :title="$tc('maintenance.insights')"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="isLoading"
      :searchQuery="searchQuery"
      :enableSearch="true"
      :isMobile="isMobile"
      :messages="messages"
      :isRowSelectable="true"
      :selectedRowId="selectedRowId"
      @setSelectedFilterOption="setSelectedFilterOption"
      @resetFilters="resetFilters"
      @textFilter="textSearch"
      @set-selected-row-id="setSelectedRow"
    />
  </table-widget-styled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import { get, partition, concat, orderBy, uniqBy, groupBy, compact, flatten, uniq } from 'lodash'
import { dateTimeShort, secondsTohhmmss } from '@/utils/filters/time'
import { formatDateAccordingToSettings } from '@/utils/time'
import { severityRanking } from '@/utils/maintenance/healthUtils'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import { TABLE_SORT_TYPES } from '@/constants/settings'
import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import TableTextCell from '@/components/Atomic/Atoms/TableCells/TableTextCell.vue'
import AssetHealthTableSeverityMolecule from '@/components/Atomic/Molecules/AssetHealthTableSeverityMolecule'

export const TableWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.navFontNormal};
`
export default {
  inject: ['uiSettings'],
  mixins: [TimeframeMixin, resizeMixin],
  props: {
    assetNotifications: {
      type: Array,
      require: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedRowId: {
      type: String,
      default: 'row_0',
      require: true,
    },
  },
  components: {
    TableWidgetStyled,
    TableV2,
  },
  filters: {
    dateTimeShort,
    secondsTohhmmss,
  },
  data() {
    return {
      isMobile: true,
      searchQuery: '',
      sorting: {
        key: 'start',
        asc: true,
      },
      filterOptionsSelected: [],
    }
  },
  computed: {
    selectedFilterKeys() {
      return Object.keys(groupBy(this.filterOptionsSelected, '__typename'))
    },
    headers() {
      const headers = [
        {
          id: `header_code`,
          label: this.$tc('code', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'code',
          size: 'small',
        },
        {
          id: `header_label`,
          label: this.$tc('label', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'label',
          size: 'large',
        },
        {
          id: `header_type`,
          label: this.$tc('type', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'type',
          size: 'small',
        },
        {
          id: `header_oem`,
          label: this.$tc('oem', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'oem',
          size: 'medium',
        },
        {
          id: `header_severity`,
          label: this.$tc('severity', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'highestSeverity',
          size: 'medium',
        },
        {
          id: `header_start`,
          label: this.$t('maintenance.start'),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'start',
          size: 'medium',
        },
        {
          id: `header_duration`,
          label: this.$tc('duration', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'duration',
          size: 'small',
        },
        {
          id: `header_count`,
          label: this.$t('maintenance.count'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'occurrenceCount',
          size: 'small',
        },
        {
          id: `header_category`,
          label: this.$t('maintenance.component'),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'component',
          size: 'medium',
        },
      ]
      return headers
    },
    uniqueErrorTypes() {
      return uniqBy(this.filterOptionsData('Type'), 'type').map(assetNotification => {
        return { name: assetNotification.type, __typename: 'Type' }
      })
    },
    uniqueSeverity() {
      const severities = this.filterOptionsData('Severity').map(notification => {
        return notification.severities
      })

      return uniq(flatten(severities)).map(severity => {
        return { name: severity, __typename: 'Severity' }
      })
    },
    uniqueCodes() {
      const source = this.filterOptionsData('Code')
      const codes = uniqBy(source, 'code').map(assetNotification => {
        return { name: assetNotification.code, __typename: 'Code' }
      })
      return orderBy(codes, 'name')
    },
    uniqueCategories() {
      return uniqBy(this.filterOptionsData('Component'), 'component').map(assetNotification => {
        return { name: assetNotification.component, __typename: 'Component' }
      })
    },
    filtersAvailable() {
      const filters = [
        {
          id: 'filter_code',
          class: 'filter filter-code',
          modelName: 'code',
          placeholder: this.$tc('code', 2),
          searchable: false,
          options: this.uniqueCodes
            .filter(f => f.name !== '0')
            .map(m => {
              return {
                ...m,
                id: m.name,
                label: m.name,
              }
            }),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Code'),
          isMultiple: true,
        },
        {
          id: 'filter_error_type',
          class: 'filter filter-error-type',
          modelName: 'type',
          placeholder: this.$tc('type', 2),
          searchable: false,
          options: this.uniqueErrorTypes.map(m => ({
            ...m,
            id: m.name,
            label: m.name,
          })),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Type'),
          isMultiple: true,
        },
        {
          id: 'filter_severity',
          class: 'filter filter-severity',
          modelName: 'severity',
          placeholder: this.$tc('severity', 2),
          searchable: false,
          options: this.uniqueSeverity.map(m => ({
            ...m,
            id: m.name,
            label: this.$t(`severityCodes.${m?.name.toLowerCase()}`),
          })),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Severity'),
          isMultiple: true,
        },
        {
          id: 'filter_component',
          class: 'filter filter-component',
          modelName: 'component',
          placeholder: this.$tc('maintenance.component', 2),
          searchable: false,
          options: this.uniqueCategories.map(m => ({
            ...m,
            id: m.name,
            label: m.name,
          })),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Component'),
          isMultiple: true,
        },
      ]
      return filters
    },
    assetNotificationsFiltered() {
      if (this.filterOptionsSelected.length > 0) {
        return this.assetNotifications.reduce((assetNotificationsFiltered, notification) => {
          const filterGroups = groupBy(this.filterOptionsSelected, '__typename')
          const notInAllGroups = Object.keys(filterGroups).some(key => {
            const filterGroup = filterGroups[key]
            let assetPath
            let filterPath
            if (key === 'Type') {
              assetPath = 'type'
              filterPath = 'id'
            } else if (key === 'Severity') {
              assetPath = 'severities'
              filterPath = 'id'
            } else if (key === 'Code') {
              assetPath = 'code'
              filterPath = 'id'
            } else if (key === 'Component') {
              assetPath = 'component'
              filterPath = 'id'
            } else {
              throw new Error(`unhandled filter type ${key}`)
            }

            if (key === 'Severity') {
              const found = filterGroup.find(filter => get(notification, assetPath).some(a => a === get(filter, filterPath)))
              return !found
            } else {
              const found = filterGroup.find(filter => get(filter, filterPath) === get(notification, assetPath))
              return !found
            }
          })
          if (!notInAllGroups) {
            assetNotificationsFiltered.push(notification)
          }
          return assetNotificationsFiltered
        }, [])
      }
      return this.assetNotifications
    },
    assetNotificationsSorted() {
      const partitions = partition(this.assetNotificationsFiltered, x => !!get(x, this.sorting.key, null))
      const sortDirection = this.sorting.key === 'start' ? (this.sorting.asc ? 'desc' : 'asc') : this.sorting.asc ? 'asc' : 'desc'

      // The function was written because we have to sort based on highest severity ranking
      // but for other fields we are just relying on the natural ordering of the fields' value.
      const fetchSortKeyValue = (firstPartitionData, sortingKey) => {
        const valueForSortkeyInPartitionData = firstPartitionData[sortingKey]
        return sortingKey === 'highestSeverity' ? severityRanking(valueForSortkeyInPartitionData) : valueForSortkeyInPartitionData
      }
      return concat(
        orderBy(partitions[0], data => fetchSortKeyValue(data, this.sorting.key), [sortDirection]),
        partitions[1],
      )
    },
    rows() {
      const rows = this.assetNotificationsSorted.map((notification, key) => {
        const start = formatDateAccordingToSettings(DateTime.fromISO(notification.start), this.uiSettings, this.selectedTimezone)
        const duration = secondsTohhmmss(notification.duration)
        const code = notification?.code
        const label = notification?.label ?? this.$t('messages.noDataAvailable')
        const component = notification.component
        const type = notification.type === null ? '-' : notification.type
        const occurrenceCount =
          notification.occurrenceCount === null || notification.occurrenceCount === undefined ? '-' : notification.occurrenceCount
        const cells = [
          {
            id: `${key}_code`,
            sortableValue: code,
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: type,
            headerId: `header_code`,
          },
          {
            id: `${key}_label`,
            component: TableTextCell,
            label: label,
            sortableValue: label,
            headerId: `header_label`,
          },
          {
            id: `${key}_type`,
            sortableValue: type,
            headerId: 'header_type',
          },
          {
            id: `${key}_oem`,
            sortableValue: notification.oem ?? '-',
            headerId: 'header_oem',
          },
          {
            id: `${key}_severity`,
            component: AssetHealthTableSeverityMolecule,
            severities: notification?.severities,
            activeIssues: notification?.isActive ? notification?.severities : [],
            sortableValue: notification?.highestSeverity,
            headerId: `header_severity`,
          },
          {
            id: `${key}_start`,
            sortableValue: start,
            headerId: `header_start`,
          },
          {
            id: `${key}_duration`,
            sortableValue: duration,
            headerId: `header_duration`,
          },
          {
            id: `${key}_count`,
            sortableValue: occurrenceCount,
            headerId: `header_count`,
          },
          {
            id: `${key}_category`,
            component: TableTextCell,
            label: component,
            sortableValue: component,
            headerId: `header_category`,
          },
        ]
        return {
          rowId: `row_${key}`,
          id: notification.id,
          isSelected: notification.id === this.selectedRowId,
          cells,
        }
      })
      const filteredTable = rows.filter(row => {
        const props = compact(row.cells.map(item => item.sortableValue))
        return props.some(
          prop =>
            !this.searchQuery ||
            (typeof prop === 'string'
              ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
              : prop.toString(10).toLowerCase().includes(this.searchQuery)),
        )
      })
      return filteredTable
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        message =
          this.searchQuery === ''
            ? this.$tc('messages.noDataForSelectedTime')
            : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },
  methods: {
    filterOptionsData(typeName) {
      if (this.selectedFilterKeys && this.filterOptionsSelected[0]?.__typename !== typeName) {
        const localSelectedKeys = [...this.selectedFilterKeys]
        const indexOfType = localSelectedKeys?.indexOf(typeName)
        if (indexOfType > -1) {
          localSelectedKeys.splice(indexOfType, localSelectedKeys.length)
        }
        const mappedfilterOptionsSelected = this.filterOptionsSelected.filter(f => localSelectedKeys.includes(f.__typename))
        if (mappedfilterOptionsSelected.length > 0) {
          return this.assetNotifications.reduce((assetNotificationsFiltered, notification) => {
            const filterGroups = groupBy(mappedfilterOptionsSelected, '__typename')
            const notInAllGroups = Object.keys(filterGroups).some(key => {
              const filterGroup = filterGroups[key]
              let assetPath
              let filterPath
              if (key === 'Type') {
                assetPath = 'type'
                filterPath = 'id'
              } else if (key === 'Severity') {
                assetPath = 'severities'
                filterPath = 'id'
              } else if (key === 'Code') {
                assetPath = 'code'
                filterPath = 'id'
              } else if (key === 'Component') {
                assetPath = 'component'
                filterPath = 'id'
              } else {
                throw new Error(`unhandled filter type ${key}`)
              }

              if (key === 'Severity') {
                const found = filterGroup.find(filter => get(notification, assetPath).some(a => a === get(filter, filterPath)))
                return !found
              } else {
                const found = filterGroup.find(filter => get(filter, filterPath) === get(notification, assetPath))
                return !found
              }
            })
            if (!notInAllGroups) {
              assetNotificationsFiltered.push(notification)
            }
            return assetNotificationsFiltered
          }, [])
        }
      }
      return this.assetNotifications
    },
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
    textSearch(query) {
      this.searchQuery = query
    },
    setSelectedFilterOption(filter) {
      const found = this.filterOptionsSelected.find(f => f.id === filter.id)
      if (found) {
        this.filterOptionsSelected = this.filterOptionsSelected.filter(f => f.id !== filter.id)
      } else {
        this.filterOptionsSelected.push(filter)
      }
    },
    resetFilters() {
      this.filterOptionsSelected = []
    },
    setSelectedRow(id) {
      this.$emit('set-selected-row-id', id)
    },
  },
}
</script>
