var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHealthInsightStyled', [_vm.$apollo.loading ? _c('TalpaLoaderWrapper') : [_c('ClickableCardsMolecule', {
    attrs: {
      "cards": _vm.cards
    }
  }), _vm.selectedCardIssues.length === 0 ? _c('NoDataStyled', [_vm._v(_vm._s(_vm.$tc('messages.noData')))]) : _c('IssueTableOrganism', {
    attrs: {
      "assetNotifications": _vm.selectedCardIssues,
      "isLoading": _vm.loading,
      "selectedRowId": _vm.selectedIssueId
    },
    on: {
      "set-selected-row-id": _vm.setSelectedIssue
    }
  }), _c('IssueDetailsOrganism', {
    attrs: {
      "issueId": _vm.selectedIssue ? _vm.selectedIssue.id : undefined
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }