var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.issueId ? _c('IssueDetailsOrganismStyled', [_c('ApolloQuery', {
    attrs: {
      "query": _vm.ASSET_ISSUE_META_QUERY,
      "variables": _vm.variables,
      "skip": !_vm.issueId,
      "fetchPolicy": 'network-only'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _ref$result = _ref.result,
          error = _ref$result.error,
          data = _ref$result.data,
          isLoading = _ref.isLoading;
        return [!!isLoading ? _c('TalpaLoaderWrapper') : error ? _c('ErrorAtom', {
          attrs: {
            "error": error
          }
        }) : data && data.assetIssue ? [_c('IssueMetaMolecule', {
          attrs: {
            "issue": data.assetIssue
          }
        })] : _c('NoDataAtom')];
      }
    }], null, false, 1304406889)
  }), _c('ApolloQuery', {
    attrs: {
      "query": _vm.ASSET_ISSUE_RAW_DATA_QUERY,
      "variables": _vm.variables,
      "skip": !_vm.issueId
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _ref2$result = _ref2.result,
          error = _ref2$result.error,
          data = _ref2$result.data,
          isLoading = _ref2.isLoading;
        return [error ? _c('ErrorAtom', {
          attrs: {
            "error": error
          }
        }) : _c('IssueRawDataOrganism', {
          attrs: {
            "isLoading": !!isLoading,
            "assetIssueRawData": data === null || data === void 0 ? void 0 : data.assetIssue
          }
        })];
      }
    }], null, false, 2997439658)
  })], 1) : _c('WarningStyled', [_c('p', [_vm._v(_vm._s(_vm.$t('maintenance.selectedIssueNotInTimeframe')))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }