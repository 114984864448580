var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IssueRawDataActivitiesAtomStyled', [_vm.isLoading ? _c('LoadingDots') : _vm._l(_vm.groupedActivities, function (_ref) {
    var key = _ref[0],
      group = _ref[1];
    return _c('div', {
      key: key,
      staticClass: "group"
    }, [_c('CircleStyle', {
      attrs: {
        "color": group.color
      }
    }), _c('span', {
      staticClass: "type"
    }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t('activityTypes.' + group.id))) + ":")]), _c('span', [_vm._v(_vm._s(_vm.percentage(group.totalDuration)))]), _c('span', {
      staticClass: "dot"
    }, [_vm._v("·")]), _c('span', [_vm._v(_vm._s(_vm._f("humanDuration")(group.totalDuration)))])], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }