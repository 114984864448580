<template>
  <AssetHealthInsightStyled>
    <TalpaLoaderWrapper v-if="$apollo.loading" />
    <template v-else>
      <ClickableCardsMolecule :cards="cards" />
      <NoDataStyled v-if="selectedCardIssues.length === 0">{{ $tc('messages.noData') }}</NoDataStyled>
      <IssueTableOrganism
        :assetNotifications="selectedCardIssues"
        :isLoading="loading"
        @set-selected-row-id="setSelectedIssue"
        :selectedRowId="selectedIssueId"
        v-else
      />
      <IssueDetailsOrganism :issueId="selectedIssue ? selectedIssue.id : undefined" />
    </template>
  </AssetHealthInsightStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime, Interval } from 'luxon'

import { TalpaLoaderWrapper } from '@common/components'

import { getLast7DaysInterval, getLast24HoursInterval } from '../../../utils/time'

import ClickableCardsMolecule from '@/components/Atomic/Molecules/ClickableCardsMolecule'
import IssueTableOrganism from '../../SharedPages/AssetHealth/AssetHealthInsight/IssueTableOrganism'
import IssueDetailsOrganism from '../../SharedPages/AssetHealth/AssetHealthInsight/IssueDetailsOrganism'

import ASSET_QUERY from '#/graphql/operations/maintenance/assetQuery.gql'
import ASSET_ISSUES_QUERY from '#/graphql/operations/maintenance/assetIssuesQuery.gql'

const AssetHealthInsightStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 8.5rem minmax(20rem, 45vh) minmax(min-content, 1fr);
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`

export default {
  inject: ['uiSettings'],
  components: {
    TalpaLoaderWrapper,
    AssetHealthInsightStyled,
    ClickableCardsMolecule,
    IssueDetailsOrganism,
    IssueTableOrganism,
    NoDataStyled,
  },
  data() {
    return {
      asset: null,
      defaultCardId: '7days',
      isActive: true,
      issuesLast7Days: [],
      issuesLast24Hours: [],
      issuesPreviousDay: [],
      issuesCurrentShift: [],
      issuesPreviousShift: [],
      selectedRowErrorDetails: {},
    }
  },
  computed: {
    selectedCardId() {
      if (this.$route?.query?.cardId) {
        return this.$route?.query?.cardId
      } else if (this.$route?.query?.issue) {
        const issueId = this.$route.query.issue
        const firstCardWithSelectedIssue = this.cardIds.find(id => {
          return this.issueMapping[id]?.find(issue => issue.id === issueId)
        })
        if (firstCardWithSelectedIssue) {
          return firstCardWithSelectedIssue
        }
      } else {
        const firstCardWithIssues = this.cardIds.find(id => {
          return this.issueMapping[id]?.length > 0
        })
        if (firstCardWithIssues) {
          return firstCardWithIssues
        }
      }
      return this.defaultCardId
    },
    loading() {
      return this.$apollo.loading
    },
    hasShifts() {
      return this.asset?.previousShift !== null || this.asset?.currentShift !== null
    },
    last7DaysInterval() {
      return getLast7DaysInterval()
    },
    last24HoursInterval() {
      return getLast24HoursInterval()
    },
    previousDayInterval() {
      return Interval.fromDateTimes(DateTime.now().minus({ days: 1 }).startOf('day'), DateTime.now().minus({ days: 1 }).endOf('day'))
    },
    previousShiftInterval() {
      if (this.asset?.previousShift) {
        return Interval.fromISO(this.asset.previousShift.interval)
      }
      return null
    },
    currentShiftInterval() {
      if (this.asset?.currentShift) {
        return Interval.fromISO(this.asset.currentShift.interval)
      }
      return null
    },
    cardIds() {
      const ids = ['active']
      if (this.hasShifts) {
        ids.push('currentShift', 'previousShift')
      } else {
        ids.push('previousDay')
      }
      ids.push('last24Hours', 'last7Days')
      return ids
    },
    cardTranslationMapping() {
      return {
        active: 'active',
        previousShift: 'previousShift',
        currentShift: 'currentShift',
        previousDay: 'previousDay',
        last24Hours: 'last24Hours',
        last7Days: 'last7Days',
      }
    },
    issueMapping() {
      return {
        active: this.activeIssues,
        previousShift: this.issuesPreviousShift,
        currentShift: this.issuesCurrentShift,
        previousDay: this.issuesPreviousDay,
        last24Hours: this.issuesLast24Hours,
        last7Days: this.issuesLast7Days,
      }
    },
    cards() {
      return this.cardIds.map(id => {
        const count = this.issueMapping[id]?.length ?? 0
        return {
          id,
          label: this.$t('maintenance.insights'),
          badge: this.$t(`maintenance.${this.cardTranslationMapping[id]}`),
          count,
          isActive: this.selectedCardId === id,
          isDisabled: count < 1,
          isLoading: this.$apollo.loading,
          // color: this.colorMapping[id],
          to: {
            query: {
              ...this.$route.query,
              cardId: id === this.defaultCardId ? undefined : id,
            },
          },
        }
      })
    },
    activeIssues() {
      return this.issuesLast7Days?.filter(notification => notification.isActive === true)
    },
    selectedCardIssues() {
      return this.issueMapping[this.selectedCardId] ?? []
    },
    selectedIssue() {
      const issueIdByRoute = this.$route.query?.issue
      if (issueIdByRoute) {
        const found = this.selectedCardIssues.find(issue => issue.id === issueIdByRoute)
        if (found) {
          return found
        }
      }
      const r = this.selectedCardIssues.reduce((lastestByStartTime, item) => {
        if (!lastestByStartTime) {
          return item
        }
        if (DateTime.fromISO(item.start).toMillis() > DateTime.fromISO(lastestByStartTime.start).toMillis()) {
          return item
        }
        return lastestByStartTime
      }, null)
      return r
    },
    selectedIssueId() {
      return this.selectedIssue?.id || null
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2).toLowerCase() + replacedDates.slice(2, 5)
    },
  },
  methods: {
    setSelectedCardId(id) {
      this.selectedCardId = id
    },
    setSelectedIssue(id) {
      if (this.$route?.query?.issue !== id) {
        this.$router.push({
          query: {
            issue: id,
            cardId: this.$route?.query?.cardId,
          },
        })
      }
    },
  },
  apollo: {
    asset: {
      query: ASSET_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      skip() {
        return !this.$route.params.id
      },
    },
    issuesPreviousDay: {
      query: ASSET_ISSUES_QUERY,
      variables() {
        return {
          where: {
            interval: this.previousDayInterval.toISO(),
            assetIds: [this.asset?.id],
            locale: this.locale,
          },
        }
      },
      skip() {
        return this.hasShifts || !this.asset || !this.previousDayInterval
      },
      update(data) {
        return data.assetIssues
      },
    },
    issuesLast7Days: {
      query: ASSET_ISSUES_QUERY,
      variables() {
        return {
          where: {
            interval: this.last7DaysInterval.toISO(),
            assetIds: [this.asset?.id],
            locale: this.locale,
          },
        }
      },
      skip() {
        return !this.asset || !this.previousDayInterval
      },
      update(data) {
        return data.assetIssues
      },
    },
    issuesLast24Hours: {
      query: ASSET_ISSUES_QUERY,
      variables() {
        return {
          where: {
            interval: this.last24HoursInterval.toISO(),
            assetIds: [this.asset?.id],
            locale: this.locale,
          },
        }
      },
      skip() {
        return !this.asset || !this.last24HoursInterval
      },
      update(data) {
        return data.assetIssues
      },
    },
    issuesCurrentShift: {
      query: ASSET_ISSUES_QUERY,
      variables() {
        return {
          where: {
            interval: this.currentShiftInterval.toISO(),
            assetIds: [this.asset?.id],
            locale: this.locale,
          },
        }
      },
      skip() {
        return !this.asset || !this.currentShiftInterval
      },
      update(data) {
        return data.assetIssues
      },
    },
    issuesPreviousShift: {
      query: ASSET_ISSUES_QUERY,
      variables() {
        return {
          where: {
            interval: this.previousShiftInterval.toISO(),
            assetIds: [this.asset?.id],
            locale: this.locale,
          },
        }
      },
      skip() {
        return !this.asset || !this.previousShiftInterval
      },
      update(data) {
        return data.assetIssues
      },
    },
  },
}
</script>
